@import "utils/variables";
@import "utils/grid.min";

@font-face {
  font-family: 'Montserrat';
  src: url('./utils/Montserrat/Montserrat.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100vw;
  background-color: $background;
  line-height: 22px;
}

.text {

  &--primary {
    color: $main-blue;
  }

  &--important {
    font-size: 18px;
    font-weight: 700;
    color: $main-green;
  }
}

.st0 {
  flex-shrink: 0;
  fill: $main-green;
}
