.partners {
  &__background {
    background-color: white;
    padding: 30px;
    box-shadow: 0 3px 6px #00000029;
  }

  &__items {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    transition: .5s;
    overflow: hidden;

    &:hover {
      transition: .5s;
      box-shadow: 0 5px 35px 0 rgba(0,0,0,.1);
    }

    img {
      width: 160px;

      @media screen and (max-width: 750px){
        width: 100px;
      }
    }
  }
}

