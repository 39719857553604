@import "../../utils/variables";

.logo {
  &__green {
    color: $main-green;
  }
  &__blue {
    font-weight: 700;
    color: $main-blue;
  }
}
