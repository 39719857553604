@import "../../utils/variables";

.navigation {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  background-color: transparent;
  width: 100%;
  padding: 0 24px;

  &__image {
    height: 100%;
  }

  &__item {
    position: relative;
    text-decoration: none;
    font-size: 16px;
    color: $main-blue;
    padding: 0 12px;
    opacity: 0.9;

    &:last-of-type {
      font-weight: 700;
    }

    &--mobile {
      font-size:20px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin:2px 0;
      background-color: white;
      color: $main-blue;
    }

    &--active {
      color: $main-green;
    }

    &:after {
      bottom: -5px;
      content: "";
      height: 2px;
      left: 50%;
      position: absolute;
      background: $main-green;
      transition: width .3s ease 0s,left .3s ease 0s;
      width: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover:before, &:hover:after{
      width: 100%;
      left: 0;
    }
  }

  &__hamburger {
    display: none;
    height: 14px;
    width: 18px;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
    margin-right: 20px;
  }

  &__bar {
    height: 2px;
    width: 20px;
    display: block;
    position: absolute;
    background-color: $main-green;

    &:nth-child(1) {
      top: 0;
      transition: transform 0.20s ease-out 0.05s, background-color 0.05s ease 0.20s, top 0.15s ease 0.25s;
    }

    &:nth-child(2) {
      top: 6px;
      transition: ease 0.15s 0.15s;
    }

    &:nth-child(3) {
      top: 12px;
      transition: top 0.15s ease 0.25s, transform 0.15s ease-out 0.05s;
    }

    &--transform {
      background-color: $main-green;

      &:nth-child(1) {
        top: 6px;
        transform: rotate(45deg);
        transition: top 0.15s ease 0.05s, background-color 0.05s ease 0.2s, transform 0.20s ease-out 0.20s;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 6px;
        transform: rotate(-45deg);
        transition: top 0.15s ease 0.05s, transform 0.20s ease-out 0.20s;
      }
    }
  }

  &__mobile{
    display: none;
    position: fixed;
    top: 0;
    height: 100vh;
    width:100vw;
    background-color: #fff;
    padding-top: 150px;
    z-index: 1;
    transform: translate3d(0,100%,0);
    transition: transform 400ms ease-in-out, height 100ms ease-in-out;
    &--transform {
      transform: translate3d(0, 0, 0);
    }
  }
}

@media screen and (max-width: 860px){
  .navigation{
    &__menu{
      margin-right: 40px;
    }
  }
}

@media screen and (max-width: 670px){
  .navigation{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);

    &__menu{
      display: none;

      &--mobile {
        display: flex;
        flex-direction: column;
      }
    }

    &__hamburger {
      display: block;
    }

    &__item:after {
      bottom:-2px;
    }

    &__mobile {
      display: block;
    }
  }
}
