@import "../../utils/variables";

.contact {
  min-height: 50vh;
  background-image: url('../../images/footer.png');
  background-position-y: 100%;
  background-position-x: 100%;
  background-repeat: no-repeat;
  background-size: 50vh auto;
  padding-bottom: 48px;

  @media screen and (max-width: 655px) {
    min-height: unset;
  }
}

.list {
  list-style-type: none;
  padding: 0;

  li:first-child {
    margin-bottom: 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 5px 0;
    margin-left: -32px;

    a {
      padding-left: 16px;
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $main-green;
      }
    }
  }
}

.form {
  border-top: 1px solid $main-green;

  &__button {
    margin-top: 10px;
    float: right;
  }
}

.cover {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(#000, 0.15);
}

@media screen and (max-width: 991px){
  .contact {
    background-position-y: 150%;
  }
}

@media screen and (max-width: 655px){
  .contact {
    background: none;
  }
}
