.team {
  padding: 100px 0;

  &__background {
    padding: 25px 20px;
    background-color: white;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 12px;
  }

  &__image {
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    height: 250px;
    background-color: #53CB8F;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
  }

  .text-justify {
    text-align: justify;
  }
}
